// import PropTypes from 'prop-types';
// // @mui
// import { Box, Table, TableRow, TableBody, TableCell, TableContainer } from '@mui/material';
// import { LoadingButton } from '@mui/lab';
// // components
// import { useState } from 'react';
// import Iconify from '../../../../../components/Iconify';
// import Scrollbar from '../../../../../components/Scrollbar';
// import { TableHeadCustom, TableNoData } from '../../../../../components/table';
// import CustomLoader from '../../../../../components/CustomLoader';

// // ----------------------------------------------------------------------

// CaptainBonusTable.propTypes = {
//   loading: PropTypes.bool,
//   tableData: PropTypes.array,
//   dense: PropTypes.any,
//   serialNo: PropTypes.number,
//   tableLabels: PropTypes.array,
// };

// export default function CaptainBonusTable({ loading, tableLabels, tableData, dense, serialNo }) {
//   const [selected, setSelected] = useState(0);
//   return (
//     <Scrollbar>
//       <TableContainer sx={{ minWidth: 720 }} style={{ maxHeight: '70vh' }}>
//         <Table size={dense ? 'small' : 'medium'} stickyHeader aria-label="sticky table">
//           <TableHeadCustom headLabel={tableLabels} />
//           <TableBody>
//             {loading ? (
//               <Box component="tr" sx={{ height: '60vh' }}>
//                 <CustomLoader />
//               </Box>
//             ) : (
//               tableData.map((row, i) => (
//                 <CaptainBonusRow
//                   selected={selected}
//                   setSelected={setSelected}
//                   key={i}
//                   row={row}
//                   serialNo={serialNo + i}
//                 />
//               ))
//             )}

//             {!loading && tableData.length === 0 ? <TableNoData isNotFound /> : ''}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </Scrollbar>
//   );
// }

// // ----------------------------------------------------------------------

// CaptainBonusRow.propTypes = {
//   row: PropTypes.shape({
//     id: PropTypes.any,
//     captainCode: PropTypes.string,
//     remark: PropTypes.string,
//     amount: PropTypes.number,
//     createdDate: PropTypes.string,
//     dateModified: PropTypes.string,
//     approvalRejectBy: PropTypes.string,
//     approvalComment: PropTypes.string,
//     dateApproved: PropTypes.string,
//   }),
//   serialNo: PropTypes.number,
// };

// function CaptainBonusRow({ row, serialNo }) {
//   return (
//     <TableRow>
//       <TableCell align="left">{serialNo}</TableCell>
//       <TableCell align="center">{row?.captainCode || 'N/A'}</TableCell>
//       <TableCell align="center">{row?.remark}</TableCell>
//       <TableCell align="center">{row?.amount}</TableCell>
//       <TableCell align="center">{row?.createdDate}</TableCell>
//       <TableCell align="center">{row?.dateModified}</TableCell>
//       <TableCell align="right">
//         <LoadingButton
//           color="warning"
//           type="button"
//           variant="contained"
//           loading={false}
//           sx={{ whiteSpace: 'nowrap', py: 1, px: 3 }}
//         >
//           Approve By Audit{' '}
//         </LoadingButton>
//       </TableCell>
//       <TableCell align="right">
//         <LoadingButton
//           color="info"
//           type="button"
//           variant="contained"
//           loading={false}
//           sx={{ whiteSpace: 'nowrap', py: 1, px: 3 }}
//         >
//           Change Status{' '}
//         </LoadingButton>
//       </TableCell>
//       <TableCell align="center">{row?.approvalRejectBy}</TableCell>
//       <TableCell align="center">{row?.approvalComment}</TableCell>
//       <TableCell align="center">
//         <Box width="100px">{row?.dateApproved}</Box>
//       </TableCell>
//       <TableCell align="right">
//         <LoadingButton
//           color="error"
//           type="button"
//           variant="contained"
//           loading={false}
//           sx={{ whiteSpace: 'nowrap', py: 1 }}
//         >
//           <Iconify icon={'eva:trash-2-fill'} width={20} height={26} />
//         </LoadingButton>
//       </TableCell>
//     </TableRow>
//   );
// }

/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
// @mui
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  MenuItem,
  Divider,
} from '@mui/material';
// components
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import Label from '../../../../../components/Label';
import Iconify from '../../../../../components/Iconify';
import Scrollbar from '../../../../../components/Scrollbar';
import { TableHeadCustom, TableMoreMenu, TableNoData } from '../../../../../components/table';
import CustomLoader from '../../../../../components/CustomLoader';
import * as ModalTypes from '../../../../../redux/modal/modalTypes';

// ----------------------------------------------------------------------

CaptainBonusTable.propTypes = {
  loading: PropTypes.bool,
  tableData: PropTypes.array,
  dense: PropTypes.any,
  serialNo: PropTypes.number,
  tableLabels: PropTypes.array,
};

export default function CaptainBonusTable({ loading, tableLabels, tableData, dense, serialNo }) {
  const [selected, setSelected] = useState(0);

  return (
    <Scrollbar>
      <TableContainer sx={{ minWidth: 720 }} style={{ maxHeight: '70vh' }}>
        <Table size={dense ? 'small' : 'medium'} stickyHeader aria-label="sticky table">
          <TableHeadCustom headLabel={tableLabels} />
          <TableBody>
            {loading ? (
              <Box component="tr" sx={{ height: '60vh' }}>
                <CustomLoader />
              </Box>
            ) : (
              tableData.map((row, i) => (
                <BonusTableRow
                  selected={selected}
                  setSelected={setSelected}
                  key={i}
                  row={row}
                  serialNo={serialNo + i}
                />
              ))
            )}

            {!loading && tableData.length === 0 ? <TableNoData isNotFound /> : ''}
          </TableBody>
        </Table>
      </TableContainer>
    </Scrollbar>
  );
}

// ----------------------------------------------------------------------

BonusTableRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.any,
    captainCode: PropTypes.string,
    remark: PropTypes.string,
    amount: PropTypes.number,
    createdDate: PropTypes.string,
    approvalRemarks: PropTypes.string,
    dateModified: PropTypes.string,
    approvalRejectBy: PropTypes.string,
    approvalComment: PropTypes.string,
    dateApproved: PropTypes.string,
  }),
  serialNo: PropTypes.number,
};

function BonusTableRow({ row, serialNo }) {
  const { enqueueSnackbar } = useSnackbar();

  const [openMenu, setOpenMenuActions] = useState(null);

  const {
    user: { rolesDTOs },
  } = useSelector((state) => state.auth);
  const currentRole = rolesDTOs.map((r) => r.name);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const dispatch = useDispatch();

  const handleAddEvent = (action) => {
    dispatch({ type: ModalTypes.MODAL_OPEN, payload: action });
    handleCloseMenu();
  };

  return (
    <TableRow hover>
      <TableCell align="left">{serialNo}</TableCell>
      <TableCell align="center">{row?.captainCode || 'Nil'}</TableCell>
      <TableCell align="center">
        <Box width={180}>{row?.remarks || '--'}</Box>
      </TableCell>
      <TableCell align="center">{row?.amount}</TableCell>
      <TableCell align="center">{row?.createdDate ? new Date(row?.createdDate).toDateString() : '--'}</TableCell>
      <TableCell align="center">{row?.dateModified ? new Date(row?.dateModified).toDateString() : '--'}</TableCell>
      <TableCell align="center">
        <Box>
          {' '}
          {row?.approvedBy && row?.isApproved ? (
            <Label variant="filled" color="default">
              Approve By CRM
            </Label>
          ) : (
            ''
          )}
          {row?.approvedBy && !row?.isApproved ? (
            <Label variant="filled" color="default">
              Declined By CRM
            </Label>
          ) : (
            ''
          )}
          {row?.approvedBy === null ? (
            <Label variant="filled" color="default">
              CRM: Pending
            </Label>
          ) : (
            ''
          )}
        </Box>
        <Box mt={1}>
          {' '}
          {row?.approvedByAudit && row?.isApprovedByAudit ? (
            <Label variant="filled" color="info">
              Approve By Audit
            </Label>
          ) : (
            ''
          )}
          {row?.approvedByAudit && !row?.isApprovedByAudit ? (
            <Label variant="filled" color="info">
              Declined By Audit
            </Label>
          ) : (
            ''
          )}
          {!row?.approvedByAudit ? (
            <Label variant="filled" color="info">
              Audit: Pending
            </Label>
          ) : (
            ''
          )}
        </Box>
      </TableCell>
      <TableCell>
        <Box>
          <Typography fontSize={14} fontWeight="bold">
            CRM
          </Typography>
          <Typography fontSize={14}>{row?.approvedBy}</Typography>
          <Typography fontSize={14}>{!row?.approvedBy ? row?.rejectedBy : ''}</Typography>
        </Box>
        {row?.approvedByAudit ? (
          <Box>
            <Box>
              <hr />
            </Box>
            <Typography fontSize={14} fontWeight="bold">
              Audit
            </Typography>
            <Typography fontSize={14}>{row?.approvedByAudit}</Typography>
          </Box>
        ) : (
          ''
        )}
      </TableCell>
      <TableCell align="center">
        <Box width={180}>{row?.approvalRemarks || '--'}</Box>
      </TableCell>
      <TableCell align="center">
        <Box width={180}>{row?.approvalRemarksByAudit || '--'}</Box>
      </TableCell>
      <TableCell align="center">
        <Box>
          {row?.approvalDate ? new Date(row?.approvalDate).toDateString() : ''}
          {!row?.approvalDate && row?.rejectedDate ? new Date(row?.rejectedDate).toDateString() : ''}
        </Box>
        {row?.approvalDateByAudit ? (
          <Box mt={1}>
            <hr />
            <Typography fontSize={12} fontWeight="bold">
              Audit:
            </Typography>
            {row?.approvalDateByAudit ? new Date(row?.approvalDateByAudit).toDateString() : ''}
          </Box>
        ) : (
          ''
        )}
      </TableCell>
      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            row?.approvedBy === null ? (
              <>
                <Typography style={{ textAlign: 'center', color: 'orange', fontWeight: 'bold' }}>
                  CRM Approval
                </Typography>
                <Divider sx={{ borderStyle: 'dashed' }} />
                <MenuItem
                  onClick={() =>
                    handleAddEvent({
                      type: 'approve_decline',
                      data: { selectedType: 'approve', selectedIds: [row?.id], isAudit: false },
                    })
                  }
                >
                  <Iconify icon={'eva:eye-outline'} />
                  Approve
                </MenuItem>

                <MenuItem
                  onClick={() =>
                    handleAddEvent({
                      type: 'approve_decline',
                      data: { selectedType: 'decline', selectedIds: [row?.id], isAudit: false },
                    })
                  }
                >
                  <Iconify icon={'eva:edit-outline'} />
                  Decline
                </MenuItem>
              </>
            ) : (
              <>
                <Typography style={{ textAlign: 'center', color: 'teal', fontWeight: 'bold' }}>
                  {row?.approvedByAudit === null ? 'Audit Approval' : 'Audit Approved'}
                </Typography>

                {row?.approvedByAudit === null ? (
                  <>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    <MenuItem
                      onClick={() => {
                        if (
                          !['Administrator', 'Auditor', 'AuditSupervisor'].some((role) => currentRole.includes(role))
                        ) {
                          return enqueueSnackbar('User does not have permission/role to approve this!', {
                            variant: 'error',
                          });
                        }
                        handleAddEvent({
                          type: 'approve_decline',
                          data: { selectedType: 'approve', selectedIds: [row?.id], isAudit: true },
                        });
                      }}
                    >
                      <Iconify icon={'eva:eye-outline'} />
                      Approve
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        if (
                          !['Administrator', 'Auditor', 'AuditSupervisor'].some((role) => currentRole.includes(role))
                        ) {
                          return enqueueSnackbar('User does not have permission/role to approve this!', {
                            variant: 'error',
                          });
                        }
                        handleAddEvent({
                          type: 'approve_decline',
                          data: { selectedType: 'decline', selectedIds: [row?.id], isAudit: true },
                        });
                      }}
                    >
                      <Iconify icon={'eva:edit-outline'} />
                      Decline
                    </MenuItem>
                  </>
                ) : (
                  ''
                )}

                <Divider sx={{ borderStyle: 'dashed' }} />

                <MenuItem onClick={() => handleAddEvent({ type: 'delete', data: row })} sx={{ color: 'error.main' }}>
                  <Iconify icon={'eva:trash-2-outline'} />
                  Delete
                </MenuItem>
              </>
            )
          }
        />
      </TableCell>
    </TableRow>
  );
}
