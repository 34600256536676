import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { httpRequest } from '../../../../../https/http';
import * as ModalTypes from '../../../../../redux/modal/modalTypes';
import { searchCaptainDeduction } from '../../../../../redux/captains/captainActions';

const DeductionApprovalModal = ({ modalData, captainCode, startDate, endDate }) => {
  console.log('modalData:', modalData);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [reason, setReason] = useState('');
  const [fetching, setFetching] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const handleCloseModal = () => {
    dispatch({ type: ModalTypes.MODAL_CLOSE });
  };

  const handleDeductionRequest = async (approvedCaptainDeductionsId) => {
    setFetching(true);

    const reqBody = {
      approvedCaptainDeductionsId,
      approvedBy: user?.userName,
      reason,
    };
    const declineBody = {
      declinedCaptainDeductionsId: approvedCaptainDeductionsId,
      rejectedBy: user?.userName,
      reason,
    };

    try {
      const result = await httpRequest({
        url:
          // eslint-disable-next-line no-nested-ternary
          modalData?.selectedType === 'approve'
            ? modalData?.isAudit
              ? 'CaptainDeductions/ApproveCaptainDeductionsByAudit'
              : `CaptainDeductions/ApproveCaptainDeductions`
            : 'CaptainDeductions/DeclineCaptainDeductions',
        method: 'POST',
        urlType: 'captains',
        body: modalData?.selectedType === 'approve' ? reqBody : declineBody,
      });

      if (result.code === 1) {
        const data = {
          currentPage: 1,
          totalPages: 1,
          pageSize: result?.data?.captainDeductionsResponseDataDtos?.length || 50,
          totalCount: result?.data?.captainDeductionsResponseDataDtos?.length || 50,
          userData: result?.data?.captainDeductionsResponseDataDtos,
        };
        setReason('');

        handleCloseModal();
        dispatch(
          searchCaptainDeduction(
            enqueueSnackbar,
            captainCode,
            new Date(startDate).toISOString(),
            new Date(endDate).toISOString()
          )
        );

        if (result.shortDescription) {
          enqueueSnackbar(result.shortDescription, { variant: 'success' });
        }
      } else if (result.shortDescription) {
        enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }

      setFetching(false);
    } catch (error) {
      setFetching(false);
      enqueueSnackbar(error?.message || 'Something went wrong, please refresh and try again!', { variant: 'error' });
    }

    // eslint-disable-next-line consistent-return
    return true;
  };

  return (
    <Grid container spacing={3} sx={{ px: 3 }}>
      <Grid item xs={12}>
        <Box my={2}>
          <Typography fontWeight="bold">Are you sure you want to complete this action?</Typography>
        </Box>
        <Box mt={3}>
          <TextField
            autoComplete="reason"
            value={reason}
            onChange={({ target }) => setReason(target.value)}
            fullWidth
            id="outlined-basic"
            label="Enter Reason"
            variant="outlined"
            className="mb-2"
          />
        </Box>
        <Box display="flex" justifyContent="flex-end" sx={{ mt: 3 }}>
          <Button sx={{ mr: 1 }} variant="outlined" color="error" onClick={handleCloseModal}>
            Close
          </Button>
          <LoadingButton
            type="button"
            onClick={() => handleDeductionRequest(modalData?.selectedIds)}
            variant="contained"
            loading={fetching}
          >
            Confirm
          </LoadingButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DeductionApprovalModal;
