import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// form
import { useSnackbar } from 'notistack';

// @mui
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Grid, Stack, TextField } from '@mui/material';
import * as ModalTypes from '../../../../../redux/modal/modalTypes';

// components
import { useDispatch, useSelector } from '../../../../../redux/store';
import { httpRequest } from '../../../../../https/http';
import { captainListDropdownAction } from '../../../../../redux/captains/captainActions';

// import Chip from '../../../theme/overrides/Chip';

// ----------------------------------------------------------------------

DeductionsCreateForm.propTypes = {
  getDateInfo: PropTypes.func,
};

export default function DeductionsCreateForm() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.auth);

  const handleCloseModal = () => {
    dispatch({ type: ModalTypes.MODAL_CLOSE });
  };
  const { captainListDropDown } = useSelector((state) => state.captains);

  useEffect(() => {
    // dispatch(captainListDropdownAction(enqueueSnackbar));
  }, []);

  const [captainCode, setCaptainCode] = useState('');
  const [amount, setAmount] = useState('');
  const [remark, setRemark] = useState('');
  const [loading, setLoading] = useState(false);
  const [deductionType, setdeductionType] = useState('');
  const [deductionIdType, setdeductionIdType] = useState('');
  const [deductionTypeList, setdeductionTypeList] = useState([]);
  const [loadingDeduction, setLoadingDeduction] = useState(false);

  // const {
  //   captainListDropDown: { userData: allCaptainList },
  // } = useSelector((state) => state.settings);

  const handleSubmit = async () => {
    if (!captainCode) return enqueueSnackbar('Captain Code is required!', { variant: 'error' });
    if (!deductionType) return enqueueSnackbar('Deduction Type is required!', { variant: 'error' });
    if (!amount) return enqueueSnackbar('Amount is required!', { variant: 'error' });
    if (!remark) return enqueueSnackbar('Remark is required!', { variant: 'error' });

    try {
      setLoading(true);
      const result = await httpRequest({
        url: `CaptainDeductions/CaptainDeductionTopup`,
        urlType: 'captains',
        method: 'POST',
        body: {
          captainCode,
          deductionIdType,
          amount: Number(amount),
          reason: remark,
          createdBy: user?.userName,
        },
      });

      if (result.code === 1) {
        setAmount();
        setRemark();
        setdeductionType();
        setdeductionIdType();
        handleCloseModal();
        if (result?.shortDescription) enqueueSnackbar(result?.shortDescription, { variant: 'success' });
      } else if (result?.shortDescription) enqueueSnackbar(result?.shortDescription, { variant: 'error' });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar('Something went wrong, pls try again!', { variant: 'error' });
    }
    return true;
  };

  const getDeductionTypes = async () => {
    try {
      setLoadingDeduction(true);
      const result = await httpRequest({
        url: `CaptainDeductions/DeductionsTypes`,
        urlType: 'captains',
        method: 'POST',
      });

      if (result.code === 1) {
        setdeductionTypeList(result?.data);
      } else if (result?.shortDescription) enqueueSnackbar(result?.shortDescription, { variant: 'error' });
      setLoadingDeduction(false);
    } catch (error) {
      setLoadingDeduction(false);
      enqueueSnackbar('Something went wrong, pls try again!', { variant: 'error' });
    }
    return true;
  };

  useEffect(() => {
    getDeductionTypes();
  }, []);

  return (
    <Grid container spacing={3} sx={{ px: 3 }}>
      <Grid item xs={12}>
        {/* <Card sx={{ p: 3 }}> */}
        <Box>
          <Box
            sx={{
              display: 'grid',
              columnGap: 2,
              rowGap: 3,
              gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
            }}
          >
            <Autocomplete
              value={captainCode}
              inputValue={captainCode}
              onInputChange={(event, newInputValue) => {
                setCaptainCode(newInputValue);
              }}
              includeInputInList
              id="controllable-states-demo"
              options={
                captainListDropDown?.length
                  ? ['', ...new Set(captainListDropDown.map((c) => c?.captainCode || '-'))]
                  : []
              }
              renderInput={(params) => <TextField {...params} label="Select a Captain" />}
            />

            <Autocomplete
              value={deductionType}
              inputValue={deductionType}
              onInputChange={(event, newInputValue) => {
                setdeductionType(newInputValue);
                const info = deductionTypeList.find((d) => d.name === newInputValue);
                if (info?.id) setdeductionIdType(info?.id);
              }}
              includeInputInList
              id="controllable-states-demo"
              options={loadingDeduction ? ['', 'Loading...'] : deductionTypeList.map((d) => d.name)}
              renderInput={(params) => <TextField {...params} label="Deduction Type" />}
            />

            <TextField
              autoComplete="storeName"
              value={amount}
              onChange={({ target }) => setAmount(target.value)}
              fullWidth
              id="outlined-basic"
              label="Amount"
              variant="outlined"
              className="mb-4"
            />
          </Box>
          <Box sx={{ paddingY: 1 }}>
            <textarea
              value={remark}
              onChange={({ target }) => setRemark(target.value)}
              id="outlined-basic"
              placeholder="Remark"
              style={{
                width: '100%',
                padding: '12px',
                borderRadius: '10px',
                outline: 'none', // Remove the outline
                border: '1px solid #ccc', // Add a gray border
                resize: 'none', // Prevent resizing
              }}
            />
          </Box>
        </Box>

        <Stack alignItems="flex-end" sx={{ mt: 3 }}>
          <LoadingButton type="button" onClick={handleSubmit} variant="contained" loading={loading}>
            Create
          </LoadingButton>
        </Stack>
        {/* </Card> */}
      </Grid>
    </Grid>
  );
}
