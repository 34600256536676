/* eslint-disable consistent-return */
import format from 'date-fns/format';
import {
  BOOKINGS_URL,
  CAPTAINS_URL,
  MAIN_URL,
  SETTINGS_URL,
  PARTNERS_URL,
  INVENTORY_URL,
  PROCUREMENT_URL,
  OPERATIONS_URL,
  AMBASSADOR_URL,
} from '../https/http';
import noImg from '../assets/images/profile-img.png';

export const getUserType = (num) => {
  switch (num) {
    case '0':
      return 'Administrator';
    case '1':
      return 'Employee';
    case '2':
      return 'Partner';
    case '3':
      return 'Customer';
    case '4':
      return 'Captain';
    case '5':
      return 'CampusAmbassador';
    case '6':
      return 'CorporateCustomer';
    default:
      return 'ALL';
  }
};

export const USER_TYPE = [
  'Administrator',
  'Employee',
  'Partner',
  'Customer',
  'Captain',
  'CampusAmbassador',
  'CorporateCustomer',
  'ALL',
];
export const isRoleAllowed = (rolesAllowed, userRoles) =>
  rolesAllowed.some((role) => userRoles.some((userRole) => JSON.stringify(role) === JSON.stringify(userRole.name)));

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const COUPON_CHANNEL = ['NoCoupon', 'ApplyCoupon'];

export const statusOption = [
  { color: 'secondary', label: 'Approved', checkDate: false, rules: [] },
  { color: 'success', label: 'Valid', checkDate: false, rules: [] },
  { color: 'error', label: 'Used', checkDate: false, rules: [] },
  { color: 'error', label: 'Expired', checkDate: false, rules: [] },
  { color: 'error', label: 'Invalid', checkDate: false, rules: [] },
  { color: 'error', label: 'Checked In', checkDate: false, rules: [] },
  { color: 'error', label: 'Not Checked In', checkDate: false, rules: [] },
];

export const BOOKING_TYPES = [
  { id: 1000, name: 'All' },
  { id: 0, name: 'Terminal' },
  { id: 1, name: 'Advanced' },
  { id: 2, name: 'Online' },
  { id: 3, name: 'BookOnHold' },
  { id: 4, name: 'Ambassador' },
];

export const BOOKING_TYPE_ENUM = ['Terminal', 'Advanced', 'Online', 'BookOnHold', 'Ambassador', 'All']; // All = 1000

export const CHECKED_IN_ENUM = ['Not CheckedIn', 'CheckedIn'];

export const TRAVEL_STATUS = { Pending: 0, Travelled: 1, NoShow: 2, Rescheduled: 3, Ongoing: 4 };

export const TRAVEL_STATUS_ENUM = ['Pending', 'Travelled', 'NoShow', 'Rescheduled', 'Ongoing'];

export const HIRE_SERVICE_TYPE = ['One Way', 'Return'];

export const BOOKING_STATUS_ = [
  { NotSpecified: -1 },
  { Pending: 0 },
  { Approved: 1 },
  { Cancelled: 2 },
  { Created: 3 },
  { Declined: 4 },
  { Expired: 5 },
  { Failed: 6 },
  { OnLock: 7 },
  { OnPayment: 8 },
  { OnGoing: 9 },
  { Abandoned: 10 },
  { Refunded: 11 },
  { Reversed: 12 },
  { TransactionError: 13 },
  { Unsuccessful: 14 },
  { GtbCancelled: 15 },
  { Suspended: 16 },
  { Voided: 17 },
];

export const PAYMENT_TYPE = ['Data', 'Airtime', 'Cable'];

export const PAYMENT_METHODS = [
  'Cash',
  'Pos',
  'UnifiedPaymentSolutionsLtd',
  'InterswitchLtd',
  'Isonhold',
  'PayStack',
  'BankIt',
  'GtbUssd',
  'FlutterWave',
  'FlutterWaveUssd',
  'UnionBank',
  'DiamondBank',
  'FirstBank',
  'CashAndPos',
  'ZenithBank',
  'GlobalPay',
  'QuickTeller',
  'GlobalAccelerex',
  'SterlingBank',
  'EBillsPay',
  'FbnCheckOut',
  'TheTeller',
  'Woven',
  'Wallet',
  'NONE',
  'BudPay',
  'AzaPay',
  'Stellas',
  'Nomba',
  'CreditDirect',
  'All',
];

// BOOKING_STATUS start counting from -1
export const BOOKING_STATUS = [
  'Pending',
  'Approved',
  'Cancelled',
  'Created',
  'Declined',
  'Expired',
  'Failed',
  'OnLock',
  'On Payment',
  'Ongoing',
  'Abandoned',
  'Refunded',
  'Reversed',
  'Transaction Error',
  'Unsuccessful',
  'GTB Cancelled',
  'Suspended',
  'Voided',
  'Not Specified',
];

export const VEHICLE_STATUS = [
  'Idle',
  'Working',
  'InWorkshop',
  'SpecialAssignement',
  'OnSales',
  'MovedToEcobus',
  'Disabled',
  'SiezedByAuthority',
  'Accidented',
  'NoCaptain',
  'BrokenDown',
  'Patrol',
  'TerminalUse',
  'RepairRequested',
  'WorkshopReleased',
  'TripDenied',
  'PoliceStation',
  'InWorkshopAndAssigned',
  'Pickup',
  'DueForService',
  'ReturnedToEP',
  'Unspecified',
  'Hired',
];

export const JOB_TYPE = ['Repairs', 'Servicing', 'ServicingAndRepairs'];

export const RESCHEDULE_MODE = ['GIGMSAdmin', 'Android', 'IOS', 'OnlineWebsite', 'OnlineMobile', 'Whatsapp'];

export const MAINTENANCE_TYPE = ['SafetyRepair', 'ExternalRepair', 'ExpressRepair', 'SpecialRepair'];

export const CAPTAIN_TYPE = ['PermanentCaptain', 'HandoverCaptain', 'OwnerCaptain', 'VirtualCaptain'];
export const FARE_TYPE = ['Discount', 'Increase', 'None'];
export const DELAY_STATUS = ['OnTimeDeparture', 'LateDeparture'];
export const FLEET_HISTORY_TYPE = ['All', 'Blown', 'Loaded'];
export const DISCOUNT_TYPE = ['Regular', 'TimedDiscount', 'TimedAllDate'];

export const CAPTAIN_STATUS = [
  'Active',
  'OnAJourney',
  'DeActivated',
  'OnLeave',
  'Dismissal',
  'Deceased',
  'Retired',
  'Assigned',
];
// NotSet = 1000
export const PARTNER_APP_STATUS = ['Pending', 'Referred', 'Approved', 'Rejected', 'ProfileCreated', 'NotSet'];
export const getPartnerStatus = (id) => {
  switch (id) {
    case 0:
      return 'Pending';
    case 1:
      return 'Referred';
    case 2:
      return 'Approved';
    case 3:
      return 'Rejected';
    case 4:
      return 'ProfileCreated';
    default:
      return 'Not Set';
  }
};

export const JobCardType = ['SystemRequest', 'EmployeeRequest'];

export const getJobCardStatus = (id) => {
  switch (id) {
    case 0:
      return { label: 'Pending', color: 'warning' };
    case 1:
      return { label: 'ApprovedByOm', color: 'success' };
    case 2:
      return { label: 'DeclinedByOm', color: 'danger' };
    case 3:
      return { label: 'WorkshopReceived', color: 'secondary' };
    case 4:
      return { label: 'WorkshopDeclined', color: 'danger' };
    case 5:
      return { label: 'VehicleInspected', color: 'success' };
    case 6:
      return { label: 'ItemRequested', color: 'default' };
    case 7:
      return { label: 'ItemReceived', color: 'success' };
    case 8:
      return { label: 'RepairStarted', color: 'secondary' };
    case 9:
      return { label: 'RepairCompleted', color: 'successs' };
    case 10:
      return { label: 'Canceled', color: 'danger' };
    case 11:
      return { label: 'ItemSent', color: 'success' };
    case 12:
      return { label: 'SignedOffAndVerified', color: 'success' };
    case 14:
      return { label: 'IncompleteRequest', color: 'warning' };
    default:
      return { label: 'ALL', color: 'default' };
  }
};

export const JOBCARD_MAINTENANCE_STATUS = [
  'WorkshopReceived',
  'WorkshopDeclined',
  'VehicleInspected',
  'ItemRequested',
  'ItemReceived',
  'RepairStarted',
  'RepairCompleted',
  'SignedOff',
  'Canceled',
  'ItemSent',
  'SignedOffAndVerified',
];

export const getMaintenanceStatus = (type) => {
  switch (type) {
    case 0:
      return { label: 'WorkshopReceived', color: 'default' };
    case 1:
      return { label: 'WorkshopDeclined', color: 'primary' };
    case 2:
      return { label: 'VehicleInspected', color: 'info' };
    case 3:
      return { label: 'ItemRequested', color: 'info' };
    case 4:
      return { label: 'ItemReceived', color: 'success' };
    case 5:
      return { label: 'RepairStarted', color: 'info' };
    case 6:
      return { label: 'RepairCompleted', color: 'success' };
    case 7:
      return { label: 'SignedOff', color: 'default' };
    case 8:
      return { label: 'Canceled', color: 'primary' };
    case 9:
      return { label: 'ItemSent', color: 'success' };
    default:
      return { label: 'SignedOffAndVerified', color: 'default' };
  }
};

export const getCaptainType = (id) => {
  switch (id) {
    case 0:
      return 'PC';
    case 1:
      return 'HC';
    case 2:
      return 'OC';
    case 3:
      return 'VC';
    default:
      return 'Nil';
  }
};

export function getMonthName(monthNumber) {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString('en-US', {
    month: 'long',
  });
}

// Getting Booking Type using booking id
export const getBookingType = (no) => {
  let bookingName;
  BOOKING_TYPES.forEach((bookType) => {
    if (bookType.id === no) bookingName = bookType.name;
  });
  return bookingName;
};

export const getFullDate = (date) =>
  new Date(date).toLocaleString(undefined, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    weekday: 'short',
    hour: '2-digit',
    hour12: true,
    minute: '2-digit',
    second: '2-digit',
  });

export const getShortDateAndTime = (date) => {
  new Date(date).toLocaleString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  });
};
export const getShorDate = (date) => new Date(date).toDateString();

// export const getTimeString = (time) => `${time.substr(0, 5)} ${time.substr(0, 2) > 12 ? 'PM' : 'AM'}`;

export const getTimeString = (time) => {
  if (time === null) return 'N/A';
  const hours = parseInt(time?.substr(0, 2), 10);
  const minutes = time?.substr(3, 2);
  const period = hours >= 12 ? 'PM' : 'AM';
  const adjustedHours = hours % 12 || 12; // Convert 0 to 12 for midnight and handle noon correctly
  return `${adjustedHours}:${minutes} ${period}`;
};
export function hoursAndMinutes(time) {
  // Split the time string into its components
  const [hours, minutes, seconds] = time.split(':').map(Number);

  // Calculate the total minutes
  const totalMinutes = hours * 60 + minutes + Math.floor(seconds / 60);
  const finalHours = Math.floor(totalMinutes / 60);
  const finalMinutes = totalMinutes % 60;

  return `${finalHours} Hr ${finalMinutes} mins`;
}
export const getPaymentMethod = (id) => PAYMENT_METHODS[id];

export const getBookingStatus = (code) => {
  switch (code) {
    case 0:
      return 'Pending';
    case 1:
      return 'Approved';
    case 2:
      return 'Cancelled';
    default:
      return 'Not Specified';
  }
};

export const getBgColor = (bookedSeat, selectedSeats, seatNo, isTerminal) => {
  //
  if (isTerminal) {
    const seatDetails = bookedSeat.find((sigleSeat) => sigleSeat.seatNumber === seatNo);
    // isPrinted
    if (seatDetails?.isPrinted) return { bgColor: '#4CAF50', textColor: '#FFFFFF' };
    // Terminal
    if (seatDetails?.bookingType === 0) return { bgColor: '#8069ff', textColor: '#FFFFFF' };
    // Advanced
    if (seatDetails?.bookingType === 1) return { bgColor: '#8b6914', textColor: '#FFFFFF' };
    // Online
    if (seatDetails?.bookingType === 2) return { bgColor: '#ffc125', textColor: '#FFFFFF' };
    // BookOnHold
    if (seatDetails?.bookingType === 3) return { bgColor: 'deepskyblue', textColor: '#FFFFFF' };
  }

  if ([...bookedSeat, ...selectedSeats].includes(seatNo)) return { bgColor: 'rgb(226,29,0)', textColor: '#FFFFFF' };

  return { bgColor: '', textColor: '#000000' };
};

export const getBookingTypeInfo = (statusNo) => {
  switch (statusNo) {
    case 0:
      return { color: '#8069ff', status: 'Terminal Booking' };
    case 1:
      return { color: '#8b6914', status: 'Advanced Booking' };
    case 2:
      return { color: '#ffc125', status: 'Online Booking' };
    case 3:
      return { color: 'deepskyblue', status: 'Book Onhold' };
    case 4:
      return { color: 'brown', status: 'Ambassador' };
    default:
      return { color: '#E21D00', status: 'Not Specified' };
  }
};
export const getluggageType = (no) => {
  switch (no) {
    case 'Acceptable':
      return 0;
    case 'Courier':
      return 1;
    case 'Extra Seat':
      return 2;
    default:
      return 0;
  }
};

export const REPORT_PARTNER = ['GIGM', 'EP', 'EP_GIGM', 'N_A'];
export const VEHICLE_STATE = ['Operational', 'Faulty'];
export const TRANSIT_TYPE = [
  'Loaded',
  'Faulty',
  'Leftover',
  'Received_For_Operations',
  'Received_For_Repairs',
  'Blown_For_Operations',
  'Blown_For_Repairs',
  'Charter',
  'Blown_With_Guests',
  'Turnback',
  'Rescue',
  'Out_Of_Workshop',
  'To_Be_Operational',
  'Late_Arrival',
  'Special_Duty',
  'No_Captain',
  'Faulty_But_Wip',
];
export const DISPATCH_TYPE = ['Loaded', 'Blown', 'None'];
export const GENDER = ['Male', 'Female', 'Unspecified'];
export const CLASS_TYPE = ['Users', 'SeatManagement', 'Bookings', 'Jobcard', 'Customers'];
export const AMBASADOR_PARAMETER_TYPE = ['firstName', 'Email', 'PhoneNumber'];
export const PARAMETER_TYPE = ['FirstName', 'LastName', 'Email', 'PhoneNumber'];
export const CAPTAIN_SEARCH_TYPE = ['name', 'captainCode'];
export const CAPTAIN_PARAMETER_TYPE = ['VehicleRegistrationNumber'];
export const TERMINAL_TYPE = ['PHYSICAL', 'VIRTUAL'];
export const ROUTE_SCOPE = ['Local', 'International'];
export const PARTNER_TYPE = ['Corperate', 'Individual'];
export const FINANCE_MODE = ['Self', 'Bank'];
export const ROUTE_CONNECTION = ['NotApplicable', 'DirectConnection', 'TransitConnection'];
// export const ROUTE_CONNECTION = ['NotApplicable', 'DirectConnection', 'TransitConnection'];
export const VIEW_MANIFEST = [
  {
    label: 'Revalidate Ticket',
    icon: 'person-delete-outline',
    type: 'Revalidate',
    link: '',
    visibleStatus: [0, 2],
  },
];

export const ALL_TICKET_SUB_BTN = [
  {
    label: 'Reschedule',
    icon: 'clock-outline',
    type: '',
    link: 'rescheduleBooking',
    visibleStatus: [1],
  },
  {
    label: 'Reroute',
    icon: 'repeat-outline',
    type: '',
    link: 'rerouteBooking',
    visibleStatus: [1],
  },
  {
    label: 'Cancel Ticket',
    icon: 'person-delete-outline',
    type: 'Cancelled',
    link: '',
    visibleStatus: [],
    roles: [],
  },
  // more
  {
    label: 'Suspend Ticket',
    icon: 'person-delete-outline',
    type: 'Suspended',
    link: '',
    visibleStatus: [1],
    roles: [],
  },
  {
    label: 'Verify Payment',
    icon: 'credit-card-fill',
    type: 'VerifyTicket',
    link: '',
    visibleStatus: [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 16, 17],
    roles: [],
  },
  {
    label: 'Revalidate Ticket',
    icon: 'person-delete-outline',
    type: 'Revalidate',
    link: '',
    visibleStatus: [0, 1, 2],
    roles: ['Administrator', 'Auditor', 'AuditSupervisor'],
  },
];

export const ALL_TICKET_BTN = [
  {
    label: 'Reschedule',
    icon: 'clock-outline',
    type: '',
    link: 'rescheduleBooking',
    visibleStatus: [1],
    roles: [],
  },
  {
    label: 'Reroute',
    icon: 'repeat-outline',
    type: '',
    link: 'rerouteBooking',
    visibleStatus: [1],
    roles: [],
  },
  {
    label: 'Cancel Ticket',
    icon: 'person-delete-outline',
    type: 'Cancelled',
    link: '',
    visibleStatus: [],
    roles: [],
  },
  // more
  {
    label: 'Suspend Ticket',
    icon: 'person-delete-outline',
    type: 'Suspended',
    link: '',
    visibleStatus: [1],
    roles: [],
  },
  {
    label: 'View Ticket',
    icon: 'eye-outline',
    type: 'ViewTicket',
    link: '',
    visibleStatus: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 16, 17],
    roles: [],
  },
  {
    label: 'Verify Payment',
    icon: 'credit-card-fill',
    type: 'VerifyTicket',
    link: '',
    visibleStatus: [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 16, 17],
    roles: [],
  },
  {
    label: 'Revalidate Ticket',
    icon: 'person-delete-outline',
    type: 'Revalidate',
    link: '',
    visibleStatus: [0, 1, 2],
    roles: ['Administrator', 'Auditor', 'AuditSupervisor'],
  },
  {
    label: 'Void Ticket',
    icon: 'slash-fill',
    type: 'Void',
    link: '',
    visibleStatus: [1],
    roles: ['Administrator', 'GroupAccountant', 'HeadOfCustomerService', 'Auditor', 'AuditSupervisor'],
  },
  {
    label: 'Add Pickup',
    icon: 'plus-fill',
    type: '',
    link: 'addPickUp',
    visibleStatus: [1],
    roles: [],
  },
];

export const MANAGE_BOOKINGS_BTN = [
  {
    label: 'View Ticket',
    icon: 'eye-outline',
    modalType: 'ViewTicket',
    link: '',
    visibleStatus: [0, 1],
  },
  {
    label: 'Approve Ticket',
    icon: 'checkmark-square-2-outline',
    modalType: 'ApproveTicket',
    link: '',
    visibleStatus: [0],
  },
];

export const VEHICLE_FEATURES = ['central Media', 'Leg Space', 'No AC availabe'];

export const checkPrivilages = (privileges, myAssignedRoles) => {
  let hasPrivilage = false;
  // eslint-disable-next-line no-restricted-syntax
  for (const role of myAssignedRoles) {
    if (privileges.includes(role)) {
      hasPrivilage = true;
      break;
    }
  }
  return hasPrivilage;
};

export const loadImg = (imageUrl, urlType = '') => {
  let baseUrl = MAIN_URL;
  if (urlType === 'settings') baseUrl = SETTINGS_URL;
  if (urlType === 'bookings') baseUrl = BOOKINGS_URL;
  if (urlType === 'captains') baseUrl = CAPTAINS_URL;
  if (urlType === 'partners') baseUrl = PARTNERS_URL;
  if (urlType === 'inventory') baseUrl = INVENTORY_URL;
  if (urlType === 'procurement') baseUrl = PROCUREMENT_URL;
  if (urlType === 'ambassador') baseUrl = AMBASSADOR_URL;
  if (urlType === 'operations') baseUrl = OPERATIONS_URL;
  if (imageUrl?.substr(0, 4) !== '~/C:') {
    return baseUrl?.replace('apiV2', '') + imageUrl?.replace('~/', '');
  }
  return noImg;
};

export function formatDate(date = new Date()) {
  const year = date.toLocaleString('default', { year: 'numeric' });
  const month = date.toLocaleString('default', { month: '2-digit' });
  const day = date.toLocaleString('default', { day: '2-digit' });
  return [year, month, day].join('/');
}

export function formatTime(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  const strTime = `${hours}:${minutes} ${ampm}`;
  return strTime;
}

export const getWeekDay = (weekday) => {
  switch (weekday) {
    case 'Sunday':
      return 0;
    case 'Monday':
      return 1;
    case 'Tuesday':
      return 2;
    case 'Wednesday':
      return 3;
    case 'Thursday':
      return 4;
    case 'Friday':
      return 5;
    case 'Saturday':
      return 6;
    default:
      return 0;
  }
};

export const COUPON_TYPE = ['Percentage', 'Fixed', 'All']; // All = 1000
export const CURRENCY_TYPE = ['NG', 'GH'];
export const JOURNEY_TYPE = [
  'Loaded',
  'Blown',
  'Pickup',
  'VirtualBlown',
  'Rescue',
  'Transload',
  'Incompleted',
  'InTransit',
];

export const BOOKING_CHANNEL = ['Nigeria', 'Ghana', 'ALL'];
export const PAYMENT_STATUS = [
  'Paid',
  'Pending',
  'Declined',
  ' Successful',
  'Unavailable_BankCode',
  'Failed',
  'No_Response',
  'Processing',
  'In_Progress',
];
export const JOURNEY_STATUS = ['Pending', 'Approved', 'In Transit', 'Received', 'Transloaded', 'Denied', 'Incompleted'];

export const DURATION_TYPE = ['Second', 'Minute', 'Hour', 'Day', 'Month', 'Year', 'Week', 'All']; // All = 1000

export const STORE_TYPE = ['RegularStore', 'Hub', 'CentralInventory', 'Patrol'];
export const VENDOR_TYPE = ['Inventory', 'Workshop'];
export const MAINTENANCE_PART_AGE_TYPE = ['NotSpecified', 'BrandNew', 'FairlyUsed'];

export const REQUEST_ITEM_TYPE = ['NotSpecified', 'VehiclePart', 'WorkItem'];

export const REQUEST_ORDER_RAISE = [
  { value: 1, label: 'Approve' },
  { value: 2, label: 'Reject' },
];
export const ORDER_FULFILLMENT_TYPE = ['NotSpecified', 'Completed', 'Partial'];
export const PROCUREMENT_ITEM_REQUEST_STATUS = ['Initiated', 'Approved', 'Rejected', 'Pending'];

export const getProcurementRaiseOrderStatusColor = (statusNo) => {
  switch (statusNo) {
    case 0:
      return { label: 'Not Treated', color: 'info' };
    case 1:
      return { label: 'Open', color: 'warning' };
    case 2:
      return { label: 'Closed', color: 'success' };

    case 3:
    default:
      return { label: 'ALL', color: 'default' };
  }
};
export const getOrderApprovalStatusColor = (statusNo) => {
  switch (statusNo) {
    case 0:
      return { label: 'NotSpecified', color: 'primary' };
    case 1:
      return { label: 'Complete', color: 'success' };
    case 2:
      return { label: 'Partial', color: 'warning' };

    case 3:
    default:
      return { label: 'ALL', color: 'default' };
  }
};
export const getProcurementStatusColor = (statusNo) => {
  switch (statusNo) {
    case 0:
      return { label: 'Initiated', color: 'info' };
    case 1:
      return { label: 'Approved', color: 'success' };
    case 2:
      return { label: 'Rejected', color: 'error' };
    case 3:
      return { label: 'Pending', color: 'warning' };
    case 4:
    default:
      return { label: 'ALL', color: 'default' };
  }
};
export const OrderFulfillmentStatus = (statusNo) => {
  switch (statusNo) {
    case 0:
      return { label: 'NotSpecified', color: 'secondary' };
    case 1:
      return { label: 'Completed', color: 'success' };
    case 2:
      return { label: 'Partial', color: 'warning' };
    default:
      return { label: 'ALL', color: 'default' };
  }
};
// export const getProcurementStatusColor = (status) => {
//   switch (status) {
//     case 0:
//       return 'deepskyblue';
//     case 1:
//       return 'green';
//     case 2:
//       return 'red';
//     case 3:
//       return '#ffd966';
//     default:
//       return ''; // Add a default color if needed
//   }
// };
export const STOCK_REQUEST_STATUS = [
  'Pending',
  'Approved',
  'Declined',
  'SendingApproved',
  'InTransit',
  'SendingDeclined',
  'Received',
  'RecipientDenied',
  'ReceivedAndVerified',
  'ReceivedAndStocked',
  'OrderRaised',
  'ApprovedOrderRaised',
];
export const getStockRequestBgColor = (statusNo) => {
  switch (statusNo) {
    case 0:
      return { label: 'Pending', color: 'warning' };
    case 1:
      return { label: 'Approved', color: 'success' };
    case 2:
      return { label: 'Declined', color: 'error' };
    case 3:
      return { label: 'SendingApproved', color: 'info' };
    case 4:
      return { label: 'InTransit', color: 'secondary' };
    case 5:
      return { label: 'SendingDeclined', color: 'error' };
    case 6:
      return { label: 'Received', color: 'default' };
    case 7:
      return { label: 'RecipientDenied', color: 'error' };
    case 8:
      return { label: 'ReceivedAndVerified', color: 'secondary' };
    case 9:
      return { label: 'ReceivedAndStocked', color: 'success' };
    case 10:
      return { label: 'OrderRaised', color: 'secondary' };
    case 11:
      return { label: 'ApprovedOrderRaised', color: 'success' };
    default:
      return { label: 'ALL', color: 'default' };
  }
};
export function dateAndTime(dateString) {
  const date = new Date(dateString);
  return format(date, 'yyyy/MM/dd HH:mm:ss');
}
export function extractMonthYear(dateString) {
  const date = new Date(dateString);
  const options = { month: 'short', year: 'numeric' };
  return date.toLocaleDateString('en-US', options);
}
export function calculateDuration(oldDate, todayDate = new Date()) {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;

  const timeDiff = Math.abs(todayDate.getTime() - oldDate.getTime());
  const totalDays = Math.floor(timeDiff / millisecondsPerDay);

  const years = Math.floor(totalDays / 365);
  const months = Math.floor(totalDays / 30) % 12;
  const weeks = Math.floor(totalDays / 7) % 4;
  const days = totalDays % 7;

  let durationString = '';

  if (years > 0) {
    durationString += `${years} ${years === 1 ? 'year' : 'years'}, `;
  }

  if (months > 0) {
    durationString += `${months} ${months === 1 ? 'month' : 'months'}, `;
  }

  if (weeks > 0) {
    durationString += `${weeks} ${weeks === 1 ? 'week' : 'weeks'}, `;
  }

  if (days > 0) {
    durationString += `${days} ${days === 1 ? 'day' : 'days'}`;
  }

  // Remove trailing comma and space
  durationString = durationString.replace(/,\s*$/, '');

  return durationString;
}

export function getVehicleAge(vehicleAge) {
  const years = Math.floor(vehicleAge / 365);
  const months = Math.floor(vehicleAge / 30) % 12;
  const weeks = Math.floor(vehicleAge / 7) % 4;
  const days = vehicleAge % 7;

  let durationString = '';

  if (years > 0) {
    durationString += `${years} ${years === 1 ? 'year' : 'years'}, `;
  }

  if (months > 0) {
    durationString += `${months} ${months === 1 ? 'month' : 'months'}, `;
  }

  if (weeks > 0) {
    durationString += `${weeks} ${weeks === 1 ? 'week' : 'weeks'}, `;
  }

  if (days > 0) {
    durationString += `${days} ${days === 1 ? 'day' : 'days'}`;
  }

  // Remove trailing comma and space
  durationString = durationString.replace(/,\s*$/, '');

  return durationString;
}

export const getFileTypeLabel = (fileType) => {
  switch (fileType) {
    case 'pdf':
      return 'PDF Document';
    case 'doc':
      return 'Word Document';
    case 'excel':
      return 'Excel Spreadsheet';
    default:
      return 'Supporting Document';
  }
};

export const getFileType = (fileUrl) => {
  if (!fileUrl) return;
  const fileExtension = fileUrl.split('.').pop().toLowerCase();

  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
  const pdfExtensions = ['pdf'];
  const excelExtensions = ['xls', 'xlsx'];
  const csvExtensions = ['csv'];
  const docExtensions = ['doc', 'docx'];

  if (imageExtensions.includes(fileExtension)) {
    return 'image';
  }
  if (pdfExtensions.includes(fileExtension)) {
    return 'pdf';
  }
  if (excelExtensions.includes(fileExtension)) {
    return 'excel';
  }
  if (csvExtensions.includes(fileExtension)) {
    return 'csv';
  }
  if (docExtensions.includes(fileExtension)) {
    return 'doc';
  }
  return 'unknown';
};

export function cleanAndParseJSON(jsonString) {
  try {
    // Remove backslashes from the string
    const cleanedString = jsonString.replace(/\\/g, '');
    // Parse the cleaned string to get the JSON object
    const parsedObject = JSON.parse(cleanedString);
    // Return the cleaned object
    return parsedObject;
  } catch (error) {
    return null;
  }
}

export const ROUTE_TYPE = [
  'Short',
  'Medium',
  'Long',
  'BackwardPickup',
  'Lite', // Add new route types after Lite not NONE. NONE will be the last
  'NONE', // = 1000
];

export const ALL_ROLES = [
  'Administrator',
  'Auditor',
  'AuditSupervisor',
  'CustomerRepresentative',
  'CustomerRepSupervisor',
  'FleetManager',
  'GroupAccountant',
  'HumanResource',
  'IntelligentControlUnit',
  'InventoryAdmin',
  'InventoryOfficer',
  'InventorySupervisor',
  'OnlineBookingRepresentative',
  'Partner',
  'PatrolTeamMember',
  'ReportViewer',
  'TerminalAccountant',
  'OperationHead',
  'OperationsManager',
  'TerminalOfficer',
  'Workshop',
  'WorkshopSupervisor',
  'WorkshopAdministrator',
  'Ticketer',
  'OnlineBookingManager',
  'RegionalManager',
  'EnterpriseBusinessManager',
  'Marketing&Innovation',
  'GLA',
  'IT Support',
  'ThirdPartyManager',
  'LineManager',
  'Captain Relation Officer',
  'Captain Relation Manager',
  'PartnerRelationsManager',
  'DashboardReportsAccess',
  'MarketingDashboardReportsAccess',
  'ProcurementOfficer',
  'ChiefProcurementOfficer',
  'CEO',
];
